import React, { useState } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, parseISO } from 'date-fns';
import DebugModal from './DebugModal';
import './App.css';

const API_URL = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:5010';

const App = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [debugLogs, setDebugLogs] = useState([]);
  const [isDebugModalOpen, setIsDebugModalOpen] = useState(false);
  const [collapsedStates, setCollapsedStates] = useState({});
  const [searchPerformed, setSearchPerformed] = useState(false);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const addDebugLog = (message) => {
    setDebugLogs((prevLogs) => [...prevLogs, message]);
  };

  const fetchData = async (date) => {
    setLoading(true);
    try {
      const formattedDate = format(date, 'yyyy-MM-dd');
      addDebugLog(`Fetching data for date: ${formattedDate}`);
  
      const [lineupResponse, showsResponse] = await Promise.all([
        axios.post(`${API_URL}/api/get-lineup`, { date: formattedDate }),
        axios.post(`${API_URL}/api/get-shows`, { date: formattedDate })
      ]);
  
      addDebugLog(`Lineup response: ${JSON.stringify(lineupResponse.data)}`);
      addDebugLog(`Shows response: ${JSON.stringify(showsResponse.data)}`);
  
      const lineup = lineupResponse.data.shows;
      const shows = showsResponse.data.shows;
  
      const convertLineupTime = (timeStr) => {
        const [time, period] = timeStr.split(' ');
        let [hours, minutes] = time.split(':').map(Number);
        if (period.toLowerCase() === 'pm' && hours < 12) hours += 12;
        if (period.toLowerCase() === 'am' && hours === 12) hours = 0;
        return { hours, minutes };
      };
  
      const convertShowTime = (timeStr) => {
        const [hours, minutes] = timeStr.split(':').map(Number);
        return { hours, minutes };
      };
  
      const timesMatch = (lineupTime, showTime) => {
        return lineupTime.hours === showTime.hours && lineupTime.minutes === showTime.minutes;
      };
  
      const availableEvents = lineup.map(lineupShow => {
        const lineupTime = convertLineupTime(lineupShow.time);
  
        const matchingShow = shows.find(show => {
          const showTime = convertShowTime(show.time);
          return timesMatch(lineupTime, showTime);
        });
  
        if (matchingShow && matchingShow.total_guests < matchingShow.max_seats) {
          return {
            ...lineupShow,
            ...matchingShow,
            availableSeats: matchingShow.max_seats - matchingShow.total_guests
          };
        }
        return null;
      }).filter(Boolean);
  
      addDebugLog(`Available events: ${JSON.stringify(availableEvents)}`);
      setEvents(availableEvents);

      // Initialize collapsed states
      const initialCollapsedStates = availableEvents.reduce((acc, _, index) => {
        acc[index] = true;
        return acc;
      }, {});
      setCollapsedStates(initialCollapsedStates);
      
    } catch (error) {
      addDebugLog(`Error fetching data: ${error.message}`);
      if (error.response) {
        addDebugLog(`Error response data: ${JSON.stringify(error.response.data)}`);
        addDebugLog(`Error response status: ${error.response.status}`);
        addDebugLog(`Error response headers: ${JSON.stringify(error.response.headers)}`);
      } else if (error.request) {
        addDebugLog(`Error request: ${JSON.stringify(error.request)}`);
      } else {
        addDebugLog(`Error message: ${error.message}`);
      }
      console.error('Error fetching data:', error);
    }
    setLoading(false);
    setSearchPerformed(true);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleDateChangeMobile = (e) => {
    const date = parseISO(e.target.value);
    addDebugLog(`Mobile date selected: ${date}`);
    setSelectedDate(date);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addDebugLog(`Submitting date: ${selectedDate}`);
    fetchData(selectedDate);
    // setIsDebugModalOpen(true);
  };

  const formatTime = (timeStr) => {
    const [hours, minutes, seconds] = timeStr.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    return `${formattedHours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')} ${period}`;
  };

  const toggleCollapse = (index) => {
    setCollapsedStates((prevStates) => ({
      ...prevStates,
      [index]: !prevStates[index],
    }));
  };

  return (
    <div className="min-h-screen bg-bg-color text-text-color flex flex-col items-center justify-center p-4 fadeIn">
      <h1 className="text-3xl font-semibold mb-8 text-center">Comedy Cellar Available Show Tickets</h1>
      <form onSubmit={handleSubmit} className="mb-8 w-full max-w-md flex flex-col items-center">
        <div className="flex flex-col sm:flex-row items-center justify-center w-full">
          {isMobile ? (
            <input
              type="date"
              value={format(selectedDate, 'yyyy-MM-dd')}
              onChange={handleDateChangeMobile}
              className="p-2 border border-border-color rounded-md sm:rounded-l-md sm:rounded-r-none focus:outline-none focus:ring-2 focus:ring-accent-color bg-bg-color text-text-color w-full sm:w-auto mb-4 sm:mb-0"
            />
          ) : (
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              className="p-2 border border-border-color rounded-md sm:rounded-l-md sm:rounded-r-none focus:outline-none focus:ring-2 focus:ring-accent-color bg-bg-color text-text-color w-full sm:w-auto mb-4 sm:mb-0"
            />
          )}
          <button
            type="submit"
            className="bg-accent-color text-white px-4 py-2 rounded-md sm:rounded-r-md sm:rounded-l-none hover:bg-accent-color-dark focus:outline-none focus:ring-2 focus:ring-accent-color w-full sm:w-auto"
          >
            Get Shows
          </button>
        </div>
      </form>
      {loading ? (
        <p className="text-secondary-color">Loading...</p>
      ) : (
        <div className="w-full max-w-2xl slideIn">
          {searchPerformed && events.length === 0 ? (
            <p className="text-secondary-color text-center">No events found for the selected date.</p>
          ) : (
            events.map((event, index) => (
              <div key={index} className="bg-bg-color p-6 rounded-lg shadow-md mb-4 border border-border-color collapsible-container">
                <div
                  onClick={() => toggleCollapse(index)}
                  className="cursor-pointer"
                >
                  <div className="flex justify-between items-center">
                    <span>{formatTime(event.time)}</span>
                    <span>{event.title}</span>
                    <span>{event.availableSeats} Available</span>
                  </div>
                </div>
                <div className={`collapsible-content ${!collapsedStates[index] ? 'expanded' : ''}`}>
                  <div className="mt-4">
                    <div className="mb-4 text-sm font-medium text-secondary-color">
                      Total Guests: {event.total_guests}, Max Seats: {event.max_seats}
                    </div>
                    <h2 className="text-xl font-semibold mb-2">{event.title}</h2>
                    <p className="text-secondary-color mb-1">Time: {formatTime(event.time)}</p>
                    <p className="text-secondary-color mb-1">Available Seats: {event.availableSeats}</p>
                    <p className="text-secondary-color mb-4">Cover Charge: ${event.cover_charge}</p>
                    <h3 className="text-lg font-semibold mt-4 mb-2">Performers:</h3>
                    <ul className="list-disc list-inside text-secondary-color">
                      {event.performers.map((performer, idx) => (
                        <li key={idx} className="mb-2">
                          <span className="font-medium">{performer.name}</span>
                          {performer.description && (
                            <span className="text-secondary-color"> - {performer.description}</span>
                          )}
                        </li>
                      ))}
                    </ul>
                    <button
                      onClick={() => window.open(`https://www.comedycellar.com/reservations-newyork/?showid=${event.id}`, '_blank')}
                      className="mt-4 bg-accent-color text-white px-4 py-2 rounded-md hover:bg-accent-color-dark focus:outline-none focus:ring-2 focus:ring-accent-color w-full"
                    >
                      Make Reservation
                    </button>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      )}
      <DebugModal
        isOpen={isDebugModalOpen}
        logs={debugLogs}
        onClose={() => setIsDebugModalOpen(false)}
      />
    </div>
  );
};

export default App;