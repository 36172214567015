import axios from 'axios';
import { format } from 'date-fns';

const API_URL = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:5010';

const convertLineupTime = (timeStr) => {
  const [time, period] = timeStr.split(' ');
  let [hours, minutes] = time.split(':').map(Number);
  if (period.toLowerCase() === 'pm' && hours < 12) hours += 12;
  if (period.toLowerCase() === 'am' && hours === 12) hours = 0;
  return { hours, minutes };
};

const convertShowTime = (timeStr) => {
  const [hours, minutes] = timeStr.split(':').map(Number);
  return { hours, minutes };
};

const timesMatch = (lineupTime, showTime) => {
  return lineupTime.hours === showTime.hours && lineupTime.minutes === showTime.minutes;
};

export const fetchDataForDates = async (dates) => {
  const allEvents = [];

  for (const date of dates) {
    const formattedDate = format(date, 'yyyy-MM-dd');

    try {
      const [lineupResponse, showsResponse] = await Promise.all([
        axios.post(`${API_URL}/api/get-lineup`, { date: formattedDate }),
        axios.post(`${API_URL}/api/get-shows`, { date: formattedDate })
      ]);

      const lineup = lineupResponse.data.shows;
      const shows = showsResponse.data.shows;

      const availableEvents = lineup.map(lineupShow => {
        const lineupTime = convertLineupTime(lineupShow.time);

        const matchingShow = shows.find(show => {
          const showTime = convertShowTime(show.time);
          return timesMatch(lineupTime, showTime);
        });

        if (matchingShow && matchingShow.total_guests < matchingShow.max_seats) {
          return {
            ...lineupShow,
            ...matchingShow,
            availableSeats: matchingShow.max_seats - matchingShow.total_guests,
            date: formattedDate
          };
        }
        return null;
      }).filter(Boolean);

      allEvents.push(...availableEvents);

    } catch (error) {
      console.error(`Error fetching data for date ${formattedDate}:`, error);
    }
  }

  return allEvents;
};