import React, { useState, useEffect, useRef } from 'react';
import { format, addDays, isValid, parseISO } from 'date-fns';
import { fetchDataForDates } from './FetchData';
import './CalendarTab.css';

const CalendarTab = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalEvent, setModalEvent] = useState(null);
  const [apiMessage, setApiMessage] = useState('');
  const modalRef = useRef(null);

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      setApiMessage('Please hold, respectfully calling the Comedy Cellar API.');

      try {
        const today = new Date();
        const dates = Array.from({ length: 14 }, (_, i) => addDays(today, i));
        const allEvents = [];

        for (const date of dates) {
          const eventsForDate = await fetchDataForDates([date]);
          allEvents.push(...eventsForDate);

          // Add a delay to avoid hitting the API rate limit
          await new Promise(resolve => setTimeout(resolve, 500));
        }

        console.log('All available events:', allEvents);
        setEvents(allEvents);
        setApiMessage('');
      } catch (error) {
        console.error('Error fetching events:', error);
        setApiMessage('Error fetching events. Please try again later.');
      }

      setLoading(false);
    };

    fetchEvents();
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setModalEvent(null);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setModalEvent(null);
    }
  };

  const renderCalendar = () => {
    const today = new Date();
    const days = Array.from({ length: 14 }, (_, i) => addDays(today, i));

    return (
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 slideIn">
        {days.map((day, index) => (
          <div key={index} className="p-4 border rounded-lg shadow-sm">
            <div className="text-lg font-semibold">{format(day, 'MMM d')}</div>
            <div className="text-sm text-gray-600">{format(day, 'EEEE')}</div>
            <div className="mt-2 space-y-2">
              {events
                .filter(event => {
                  if (!event.timestamp) return false;
                  const eventDate = parseISO(event.timestamp);
                  return isValid(eventDate) && format(eventDate, 'yyyy-MM-dd') === format(day, 'yyyy-MM-dd');
                })
                .map((event, idx) => {
                  const eventDateTime = parseISO(event.timestamp);
                  if (!isValid(eventDateTime)) {
                    console.error('Invalid event timestamp:', event.timestamp);
                    return null;
                  }
                  return (
                    <div
                      key={idx}
                      className="p-2 bg-accent-color text-white rounded-lg cursor-pointer hover:bg-opacity-75 transition transform hover:scale-105"
                      onClick={() => setModalEvent(event)}
                    >
                      {format(eventDateTime, 'hh:mm a')} - {event.title}
                    </div>
                  );
                })}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderModal = () => {
    if (!modalEvent) return null;

    const eventDateTime = parseISO(modalEvent.timestamp);
    if (!isValid(eventDateTime)) return null;

    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50" onClick={handleOutsideClick}>
        <div ref={modalRef} className="bg-white rounded-lg w-full max-w-md mx-4 my-4 max-h-screen flex flex-col">
          <div className="bg-accent-color text-white p-4 rounded-t-lg">
            <h2 className="text-2xl font-bold">Comedy Cellar Available Show Tickets</h2>
          </div>
          <div className="p-6 overflow-y-auto flex-1">
            <p className="text-sm text-gray-600 mb-2">Time: {format(eventDateTime, 'hh:mm a')}</p>
            <p className="text-sm text-gray-600 mb-2">Available Seats: {modalEvent.availableSeats}</p>
            <p className="text-sm text-gray-600 mb-2">Cover Charge: ${modalEvent.cover_charge}</p>
            <p className="text-sm text-gray-600 mb-4">{modalEvent.description}</p>
            <h3 className="text-lg font-semibold mb-2">Performers:</h3>
            <ul className="list-disc list-inside mb-4">
              {modalEvent.performers.map((performer, idx) => (
                <li key={idx}>
                  <strong>{performer.name}:</strong> {performer.description}
                </li>
              ))}
            </ul>
          </div>
          <div className="bg-gray-100 p-4 rounded-b-lg flex justify-end space-x-2">
            <button
              className="bg-accent-color text-white px-4 py-2 rounded hover:bg-opacity-75 transition"
              onClick={() => window.open(`https://www.comedycellar.com/reservations-newyork/?showid=${modalEvent.id}`, '_blank')}
            >
              Make Reservation
            </button>
            <button
              className="bg-secondary-color text-white px-4 py-2 rounded hover:bg-opacity-75 transition"
              onClick={() => setModalEvent(null)}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="p-4 flex flex-col items-center justify-center">
      {loading ? <p className="text-center">{apiMessage}</p> : renderCalendar()}
      {renderModal()}
    </div>
  );
};

export default CalendarTab;