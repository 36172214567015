// DebugModal.js
import React from 'react';

const DebugModal = ({ isOpen, logs, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-4 max-w-lg w-full">
        <h2 className="text-xl font-semibold mb-4">Debug Logs</h2>
        <pre className="bg-gray-100 p-2 rounded-lg overflow-auto max-h-64">
          {logs.map((log, index) => (
            <div key={index}>{log}</div>
          ))}
        </pre>
        <button
          onClick={onClose}
          className="mt-4 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 w-full"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default DebugModal;