// Tabs.js
import React, { useState } from 'react';
import CalendarTab from './CalendarTab';
import App from './App'; // Import the App component
import './Tabs.css';

const Tabs = () => {
  const [activeTab, setActiveTab] = useState('shows');

  return (
    <div className="tabs">
      <div className="tab-buttons">
        <button
          className={`tab-button ${activeTab === 'shows' ? 'active' : ''}`}
          onClick={() => setActiveTab('shows')}
        >
          Day View
        </button>
        <button
          className={`tab-button ${activeTab === 'calendar' ? 'active' : ''}`}
          onClick={() => setActiveTab('calendar')}
        >
          Calendar View
        </button>
      </div>
      <div className="tab-content">
        {activeTab === 'shows' && <App />}
        {activeTab === 'calendar' && <CalendarTab />}
      </div>
    </div>
  );
};

export default Tabs;